import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  #http = inject(HttpClient);

  constructor() { }

  getImageByHash(hash : string): Observable<Blob> {
    const params = new HttpParams()
      .set('hash', hash || '');
    return this.#http.get(`${environment.shoppinggooUrl}/file/image`, { params , responseType: 'blob' });
  }
}
